import React, { useEffect, useState } from 'react';
import ChatRoomForm from './bottom-area/chat-room-form-v2';
import ChatMessages from './upper-area/chat-messages';
import useSetChatSession from '../../hooks/chat-room/useSetChatSession';
import { useChatRoom } from '../../hooks/chat-room';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from '../../lib/redux/chat/slice';
import usePreviewMode from '../../hooks/iframe/usePreviewMode';
import { getTabData } from '../platform/data';
import Welcome from './upper-area/welcome';
import useEmbedMode from '../../hooks/iframe/useEmbedMode';

export default function ChatRoom() {
  const [advancedActiveTabData, setAdvancedActiveTabData] = useState(null);
  const chat = useSelector((state) => state.chat);
  const auth = useSelector((state) => state.auth.data);
  const [advanceStyling, setAdvancedStyling] = useState({});
  const { createChatSession } = useSetChatSession();
  const { sidebar } = useSelector((state) => state.navigation.data);
  const { advanceActiveTab, mode, hostInfo } = useSelector(
    (state) => state.embed
  );
  const embedPreview = usePreviewMode();
  const embed = useEmbedMode();
  const dispatch = useDispatch();
  const {
    generating,
    streaming,
    handleUserPromptFormSubmit,
    handlePromptChange,
    handleStopRespondingBtnClick,
  } = useChatRoom();

  useEffect(() => {
    dispatch(chatActions.generatingUpdated(generating));
  }, [generating, dispatch]);

  useEffect(() => {
    dispatch(chatActions.streamingUpdated(streaming));
  }, [streaming, dispatch]);

  useEffect(() => {
    if (!window.creatingSession && !embedPreview) {
      window.creatingSession = true;
      createChatSession();
    }
  }, [chat.sequence, auth.user]);

  useEffect(() => {
    if (mode === 'advanced' && advanceActiveTab) {
      setAdvancedStyling({
        backgroundColor: 'var(--background) !important',
        borderRadius: '2px',
        paddingLeft: 'unset',
        padding: '0px 10px',
        margin: '0px 10px',
        boxShadow: '0 0 9px #0003',
        border: '.5px solid var(--light-faded-gray)',
      });
    }
  }, [mode, advanceActiveTab, advancedActiveTabData]);

  useEffect(() => {
    if (advanceActiveTab) {
      setAdvancedActiveTabData(getTabData(advanceActiveTab));
    }
  }, [advanceActiveTab]);

  const containerStyle = { marginRight: '10px' };
  if (embed) {
    containerStyle.marginLeft = '10px !important';
    containerStyle.marginRight = '10px !important';
    containerStyle.paddingLeft = 'unset !important';
  }

  return (
    <div
      className={`body-right-side light-grey-bg-dark-mode no-padding`}
      style={mode === 'advanced' ? advanceStyling : containerStyle}
    >
      {mode === 'advanced' &&
        advancedActiveTabData &&
        advancedActiveTabData.showHeader && (
          <div
            className="w-layout-blockcontainer container-45 w-container"
            style={{
              marginBottom: '8px',
              ...(mode === 'advanced'
                ? {
                    position: 'relative',
                    left: '-10px',
                    right: '-10px',
                    minWidth: '500px',
                  }
                : {}),
            }}
          >
            <div
              className="w-layout-hflex flex-block-17-copy"
              style={{ alignItems: 'center' }}
            >
              <div className="w-layout-blockcontainer container-47 w-container"></div>
              <div prop-data-names="label" className="text-block-88">
                <strong prop-data-names="" className="bold-text-12">
                  {advancedActiveTabData.display}
                </strong>
              </div>
              <div className="text-block-84">
                <a href="#" target="_blank" className="link">
                  {hostInfo?.title || ''}
                </a>
              </div>
            </div>
          </div>
        )}
      {chat?.messages?.length > 0 || mode === 'advanced' ? (
        <ChatMessages generating={generating} streaming={streaming} />
      ) : (
        <div className="upper-area">
          <Welcome />
        </div>
      )}
      <ChatRoomForm
        handlePromptChange={handlePromptChange}
        handleUserPromptFormSubmit={handleUserPromptFormSubmit}
        handleStopRespondingBtnClick={handleStopRespondingBtnClick}
        generating={generating}
        streaming={streaming}
      />
    </div>
  );
}
